@import '../../variables';
/* here are styles for main page */
.main-page {
  padding: 20px;
  background-color: $main-background;
  box-shadow: $box-shadow;
  margin-bottom: 20px;
  /* here are styles for the first block */
  .purpose-section { 
    padding: 20px 60px;
      
    @media (max-width: 768px) {
      padding: 2px 6px;
    }
    .advices-content {
      margin: 0 auto;
      
      h1 {
        margin-bottom: 10px;
        font-size: 24px; font-weight: 600;
        text-indent: 1em; line-height: 1.2;
        span {
          color: darkorange;
        }
      }
      p {
        font-size: 16px; font-style: italic;
        line-height: 1.5;
       
      }
      h2 {
        font-size: 30px; font-weight: 800;
        text-indent: 1em;
        span {
          color: darkorange;
        }
      }
      ol {
        text-align: left;
        margin-bottom: 20px;
        li {
          font-size: 16px;
          line-height: 1.5;
          margin-bottom: 10px;
          strong {
            font-weight: bold;
          }
        }
      }
    }
  }
  /* here are styles for the second block */
  .product-groups-section {  
    .card-wrapper {
      height: 100%;
      /* smooth transition for backlight effect */
      transition: box-shadow 0.3s ease; 
      .card-header, h3 {
        font-weight: bold; 
        color: darkgreen; 
      }
      &:hover {
        /* сhange shadow when hovering over card */
        box-shadow: 0 0 10px $shadow-effect;         
      }
    }
    h3 {
        font-size: 22px;
        margin-bottom: 8px;
      }
    .product-group-image {
        width: 100%; /* Adjust value to resize image as desired */
        height: auto; /* Maintain aspect ratio */
      } 
  } 
}
/* here are styles for gate page */
.gates-page {
  padding: 20px; margin-bottom: 20px;
  background-color: $main-background;
  box-shadow: $box-shadow;

  /* here are styles for the first block */
  .products-section {
    h1 {
      font-size: 30px;
      margin-bottom: 16px;
    }
    .blur {
      filter: blur(3px); 
      pointer-events: none; 
    }
    .product-card {
      margin-bottom: 20px;    
      @media (max-width: 768px) {
        .product-button {
          margin-top: 10px;
          width: 50%;
        }
      } 
      .add-button, .view-button, .quantity-input {
        font-size: 1rem;
        padding: 0.4rem 0.75rem;
      }
      .add-button {
        width: 100%;
      }      
      .view-button {
        width: 100%;
        margin-top: 10px;
      }
    } 
    .card-highlight {
      border: 3px solid $yellow-border;
      box-shadow: 0 0 10px $shadow-effect;
      .card-header {
        font-weight: bold; 
        color: darkgreen; 
      }
    }
    .product-image {
      width: 100%; /* Adjust value to resize image as desired */
      height: auto; /* Maintain aspect ratio */
    } 
  }
  /* here are styles for the second block */
  .order-section {
    h2 {
      font-size: 24px;
      margin-bottom: 10px;
    }
    .accordion {
      margin-top: 30px; margin-bottom: 20px; 
      &:hover {
        h3 {
          font-weight: bold; 
          color: darkgreen; 
        }
      }
      h3 {
        font-size: 20px;
      }
      h4 {
        font-size: 18px; font-weight: bold;
        margin-bottom: 10px;
      }
      p {
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 16px;
      }
      ol {
        text-align: left;
        margin-bottom: 20px;
        li {
          font-size: 16px;
          line-height: 1.5;
          margin-bottom: 10px;
          strong {
            font-weight: bold;
          }
        }
      }

    }   
  }
}
/* here are styles for modal window */
.modal-detail {
  .product-image {
    width: 100%; /* Adjust value to resize image as desired */
    height: auto; /* Maintain aspect ratio */
  }
}
/* here are styles for roller page */